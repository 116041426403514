import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD46seq_rEipyM6yREuKjCvTBpe9CQz6pA",
    authDomain: "telasxcorte-7cd6e.firebaseapp.com",
    projectId: "telasxcorte-7cd6e",
    storageBucket: "telasxcorte-7cd6e.appspot.com",
    messagingSenderId: "612403290446",
    appId: "1:612403290446:web:7cbfc42a9f3c607e123e1f"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app)

