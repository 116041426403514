import React from "react";

const Footer = () => {

    const defensa = <a href='https://autogestion.produccion.gob.ar/consumidores' target="_BLANK">ingrese aquí</a>

    return (
        <footer>
            <article>
                Copyright TELAS X CORTE - 2022. Todos los derechos reservados. Defensa de las y los consumidores. Para reclamos {defensa}
            </article>
        </footer>
    )
}

export default Footer