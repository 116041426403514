import React from "react";

import './styles.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ContactoForm from "./components/ContactoForm/ContactoForm";
import Footer from "./components/Footer/Footer";

const App = () => {
  return (
    <div className='appContainer'>
      <BrowserRouter>
          <header>
          </header>
          <main>
            <ContactoForm /> 
          </main>
          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;