import React, { useState, useRef } from "react";
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm  } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import emailjs from '@emailjs/browser';

const ContactoForm = () => {

    const form = useRef()

    const [contact, setContact] = useState({
        nombre: "",
        email: "",
        telefono: "",
        dni: "",
        localidad: "",
        mensaje: ""
    });

    const sendEmail = (e) => {
    
    emailjs.sendForm('contacto', 'mail-contacto', form.current, 'V1gE2oYSoWG4N9-_r')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    function Web() {
        window.location.replace('https://telasxcorte.com.ar/');
    
        return null;
    }

    const createContact = () => {
        
        addDoc(collection(db, "contact"), {
            contact
        }).then(() => {
            sendEmail()
            navigate(Web())
        }).catch(error => {
            console.log(error)
        })
    }
    
    return(
        <section>
            <div style ={ {backgroundImage: `url('./img/bkg.webp')` } } className="bkg">
            </div>
            <div className="contacto">
                <div className="container-info">
                    <img src="./img/logo.webp"/>
                    <article>
                            TelasxCorte es una empresa dedicada a la venta de textiles para profesionales del rubro de decoración, arquitectura y ambientación.
                            <br/><br/>
                            Conocé nuestra amplia variedad de telas de algodón, y fibras mezcla en la mayoría de sus casos tejidos listos para ser utilizados ya que cuentan con un proceso de lavado previo.
                    </article>
                </div>
                <div className="container-bajada">
                    <article>
                        INGRESÁ TUS DATOS PARA QUE NOS CONTACTEMOS CON VOS Y PUEDAS REALIZAR PEDIDOS.
                    </article>
                </div>
                <div className="container-form">
                    <form ref={form} onSubmit={handleSubmit(createContact)}>
                        <input 
                            type="text" 
                            name="nombre" 
                            placeholder="NOMBRE" 
                            value={contact.nombre}
                            {...register("nombre", { required: "El campo NOMBRE es obligatorio" })}
                            onChange={(e) => setContact({...contact, nombre: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="nombre"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="email" 
                            name="email" 
                            placeholder="EMAIL" 
                            value={contact.email}
                            {...register('email', { required: "El campo EMAIL es obligatorio" })}
                            onChange={(e) => setContact({...contact, email: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="number" 
                            name="telefono" 
                            placeholder="TELÉFONO" 
                            pattern="[0-9]+" 
                            value={contact.telefono}
                            {...register('telefono', { required: "El campo TELÉFONO es obligatorio" })}
                            onChange={(e) => setContact({...contact, telefono: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="telefono"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="number" 
                            name="dni" 
                            placeholder="DNI / CUIL" 
                            pattern="[0-9]+" 
                            value={contact.dni}
                            {...register('dni', { required: "El campo DNI / CUIL es obligatorio" })}
                            onChange={(e) => setContact({...contact, dni: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="dni"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="text" 
                            name="localidad" 
                            placeholder="LOCALIDAD" 
                            value={contact.localidad}
                            {...register("localidad", { required: "El campo LOCALIDAD es obligatorio" })}
                            onChange={(e) => setContact({...contact, localidad: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="localidad"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <article>
                            Comentanos sobre tu perfil profesional para que podamos brindarte la 
                            información necesaria para comenzar tus pedidos
                        </article>

                        <textarea 
                            type="text" 
                            name="mensaje" 
                            rows="6" 
                            placeholder="MENSAJE" 
                            value={contact.message}
                            {...register("mensaje", { required: "El campo MENSAJE es obligatorio" })}
                            onChange={(e) => setContact({...contact, mensaje: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="mensaje"
                            render={({ message }) => <p>{message}</p>}
                        />
                        
                        { !contact
                            ? ""
                            : <input type="submit" value="ENVIAR" className="btnN" onClick={()=> {}}/>
                        }
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ContactoForm